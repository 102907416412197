import React, { useRef, useState, useEffect } from "react";
import Button from "./Button";

import "./ImageUpload.css";
import useMobileDetect from "../../hooks/use-mobile";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  const { isMobile } = useMobileDetect();

  const filePickerRef = useRef();
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (e) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (e.target.files || e.target.files.length === 1) {
      pickedFile = e.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };
  const pickImageHandler = () => {
    filePickerRef.current.click();
  };
  const { initialValue } = props;

  return (
    <div className="form-control">
      <input
        type="file"
        ref={filePickerRef}
        id={props.id}
        style={{ display: "none" }}
        onChange={pickedHandler}
      />
      <div className={`image-upload ${props.center && "center"}`}>
        <div
          id="video"
          style={{
            width: "100%",
            padding: "20px",
            borderRadius: "25px",
            border: "2px solid #1768AC",
          }}
        >
          {previewUrl ? (
            <video
              src={`${previewUrl}`}
              playsInline
              width={isMobile() ? 280 : 550}
              height={300}
              controls
            />
          ) : (
            initialValue && (
              <video
                src={`${initialValue}`}
                playsInline
                width={isMobile() ? 280 : 550}
                height={300}
                controls
              />
            )
          )}
        </div>
        {!props.HasNoBtn && (
          <>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {!previewUrl && (
                <p>PLEASE PICK AN {`${props.kind.toUpperCase()}`}.</p>
              )}
              <Button size="small" type="button" onClick={pickImageHandler}>
                PICK {`${props.kind.toUpperCase()}`}
              </Button>
            </div>
          </>
        )}
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;
