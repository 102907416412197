import { useCallback, useEffect, useState } from "react";

export const useAuth = () => {
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState();

  const login = useCallback(async (uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    // SCOPING VARIABLE 💕
    await localStorage.setItem(
      "expDt",
      expirationDate
    );
    await localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: expirationDate
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);
  return { token, login, logout, userId };
};
