import React, { useRef, useState, useEffect } from "react";
import Button from "./Button";
import "./ImageUpload.css";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (e) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (e.target.files || e.target.files.length === 1) {
      pickedFile = e.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };
  const pickImageHandler = () => {
    filePickerRef.current.click();
  };
  const { initialValue } = props;
  return (
    <div className="form-control">
      <input
        type="file"
        ref={filePickerRef}
        id={props.id}
        style={{ display: "none" }}
        onChange={pickedHandler}
      />
      <div className={`image-upload ${props.center && "center"}`}>
        <div
          className="image-upload__preview"
          style={{
            width: "100%",
            padding: "20px",
            borderRadius: "25px",
            border: "2px solid #1768AC",
          }}
        >
          {previewUrl ? (
            <div className="image-upload__preview">
              <img src={previewUrl} alt="Preview" />
            </div>
          ) : (
            initialValue && <img src={`${initialValue}`} alt="Preview" />
          )}
        </div>
        {!props.HasNoBtn && <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!previewUrl && (
            <p>PLEASE PICK AN {`${props.kind.toUpperCase()}`}.</p>
          )}
          <Button size="small" type="button" onClick={pickImageHandler}>
            PICK {`${props.kind.toUpperCase()}`}
          </Button>
        </div>}
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;
