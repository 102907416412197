import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Users from "./user/pages/Users";
import NewLecture from "./lectures/pages/NewLecture";
import Lectures from "./lectures/pages/Lectures";
import NewFile from "./files/pages/AddFile";
import UpdateLecture from "./lectures/pages/UpdateLecture";
import Auth from "./user/pages/Auth";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import Requests from "./lectures/pages/Requests";
import Income from "./user/pages/Income";

const App = () => {
  const { login, logout, token, userId } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/lectures" exact>
          <Lectures />
        </Route>
        <Route path="/users" exact>
          <Users />
        </Route>
        <Route path="/lecture/new" exact>
          <NewLecture />
        </Route>
        <Route path="/file/new" exact>
          <NewFile />
        </Route>
        <Route path="/requests" exact>
          <Requests />
        </Route>
        <Route path="/income" exact>
          <Income />
        </Route>
        <Route path="/lectures/:lectureId">
          <UpdateLecture />
        </Route>

        <Redirect to="/lectures" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <MainNavigation />
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
