import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
}));

function MyTable() {
  const auth = useContext(AuthContext);
  const [users, setUsers] = useState([])
  const { sendRequest } = useHttpClient();

  const fetchRequests = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}admin/users`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setUsers(responseData.users);
    } catch (error) { }
  };
  useEffect(() => {
    fetchRequests();
  }, [auth]);

  const classes = useStyles();


  const handleAgree = async (id) => {

    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}admin/suspendUser`,
      "POST",
      JSON.stringify({
        userId: id,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json"

      },

    ).catch(err =>{})
    await fetchRequests()
  };

  return (
    <div className={classes.root}>
      {users.length > 0 ?
        <TableContainer style={{ direction: "rtl" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>الايميل</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>الاسم بالكامل</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}> رقم الهاتف</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>السنة</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>ايقاف؟</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="right" className={classes.tableCell}>{row.email}</TableCell>
                  <TableCell align="right" className={classes.tableCell}>{row.fullName}</TableCell>
                  <TableCell align="right" className={classes.tableCell}>{row.phoneNumber}</TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row?.year?.name}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.isSuspended ? (
                      <Button variant="outlined" color="primary" onClick={() => handleAgree(row.id)}>
                        تفعيل
                      </Button>
                    ) :
                      (
                        <Button variant="contained" color="primary" onClick={() => handleAgree(row.id)}>
                          ايقاف
                        </Button>
                      )
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> : <div style={{ display: "flex", justifyContent: "center" }} >
          انتظر قليلا او قم باضافة بعض البيانات
        </div>
      }
    </div>
  );
}

export default MyTable;
