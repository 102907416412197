import React, { Fragment, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import FileUploader from "../../shared/components/FormElements/FileUploader";
import { MenuItem, TextField } from "@material-ui/core";

const NewPlace = () => {
  const auth = useContext(AuthContext);
  const { error, isLoading, clearError, sendRequest } = useHttpClient();
  const [courseName, setCourseName] = React.useState("");
  const [yearName, setYearName] = React.useState("");
  const [years, setYears] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const handleChangeYear = (event) => {
    setYearName(event.target.value);
    var elementPos = years.map(function (x) { return x.id; }).indexOf(event.target.value);
    var objectFound = years[elementPos];
    setCourses(objectFound.courses)
  };
  const handleChangeCourse = (event) => {
    setCourseName(event.target.value);
  };
  useEffect(() => {
    const fetchYears = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}user/get-years`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setYears(responseData.years);
      } catch (error) { }
    };
    fetchYears();
  }, [sendRequest, auth]);
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      semester: {
        value: "",
        isValid: false,
      },
      price: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: false,
      },
      file: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  const history = useHistory();

  const placeSubmitHandler = async (event) => {
    //event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      formData.append("semester", formState.inputs.semester.value);
      formData.append("price", formState.inputs.price.value);
      formData.append("year", yearName);
      formData.append("CourseId", courseName);
      formData.append("image", formState.inputs.image.value);
      formData.append("file", formState.inputs.file.value);

      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "lecture/createLectureWithFiles",
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      history.push("/");
    } catch (error) { }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="place-form" onSubmit={placeSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          type="text"
          label="Title"
          id="title"
          element="input"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={inputHandler}
          errorText="Please enter a valid title. "
        />
        <Input
          type="text"
          element="textarea"
          label="Description"
          id="description"
          validators={[VALIDATOR_MINLENGTH(5)]}
          onInput={inputHandler}
          errorText="Please enter a valid description (at least 5 characters). "
        />
        <Input
          type="number"
          element="input"
          label="Semester"
          id="semester"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={inputHandler}
          errorText="Please enter a valid semester.(1 or 2) "
        />
        <Input
          type="number"
          element="input"
          label="Price"
          id="price"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={inputHandler}
          errorText="Please enter a valid price"
        />

        <TextField
          type="number"
          select
          label="Year"
          id="year"
          onInput={inputHandler}
          value={yearName}
          onChange={handleChangeYear}
          helperText="Please select year name"
          variant="outlined"
          fullWidth
        >
          {years &&
            years.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          id="CourseName"
          select
          label="Course Name"
          value={courseName}
          onChange={handleChangeCourse}
          helperText="Please select course name"
          variant="outlined"
          fullWidth
          style={{ marginTop: "30px" }}
        >
          {courses &&
            courses.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>

        <hr style={{ borderTop: "3px solid #bbb", margin: "30px" }} />

        <ImageUpload
          id="image"
          kind="image"
          onInput={inputHandler}
          errorText={"Please Provide an Image For your Lecture."}
        />
        <hr style={{ borderTop: "3px solid #bbb", margin: "30px" }} />

        <FileUploader
          id="file"
          kind="file"
          onInput={inputHandler}
          errorText={"Please Provide an File For your Lecture."}
        />
        <hr style={{ borderTop: "3px solid #bbb", margin: "30px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            inverse
            type="button"
            // disabled={!formState.isValid}
            onClick={placeSubmitHandler}
          >
            ADD Lecture
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default NewPlace;
