import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

function MyTable() {
  const auth = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const { sendRequest } = useHttpClient();

  const fetchRequests = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}order/getAllWatingOrders`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setOrders(responseData.orders);
    } catch (error) {}
  };
  useEffect(() => {
    fetchRequests();
  }, [auth]);

  const classes = useStyles();

  const handleAgree = async (id) => {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}order/acceptOrder`,
      "POST",
      JSON.stringify({
        orderId: id,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    ).catch((err) => {});
    await fetchRequests();
  };

  return (
    <div className={classes.root}>
      {orders.length > 0 ? (
        <TableContainer style={{ direction: "rtl" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الايميل
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الاسم بالكامل
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  {" "}
                  رقم الهاتف
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الطلبات
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  السعر الكامل للطلب
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  موافقة؟
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.email}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.fullName}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.lectures.map((order) => order.title).join(", ")}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {!row.isAccepted && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAgree(row.orderId)}
                      >
                        Agree
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          انتظر قليلا او قم باضافة بعض البيانات
        </div>
      )}
    </div>
  );
}

export default MyTable;
