import React from "react";

import "./LoadingSpinner.css";
import { CircularProgress } from "@material-ui/core";

const LoadingSpinner = (props) => {
  return (
    <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default LoadingSpinner;
