import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, makeStyles
} from '@material-ui/core';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";

function MyTable() {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [lectures, setLectures] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const openAndCloseShowConfirmModalHandler = (id) => {
    setShowConfirmModal(!showConfirmModal);
    setDeletedId(id)
  }
  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}lecture/${deletedId}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      setPage(1);
    } catch (error) { }
  };

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}lecture/?page=${page}&limit=10`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLectures(responseData.lectures);
        setTotalPages(Math.floor(responseData.totalCount / 10));
      } catch (error) { }
    };

    fetchRequests();
  }, [page]);

  const handleFirstPageButtonClick = () => {
    setPage(1);
  };

  const handleBackButtonClick = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextButtonClick = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(totalPages);
  };


  const useStyles = makeStyles((theme) => ({
    root: {
      overflowX: 'auto',
    },
    tableCell: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {lectures.length > 0 ?
        <TableContainer style={{ direction: "rtl" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>رقم المحاضرة </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>عنوان الدرس</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}> السعر</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>وصف الدرس</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>الترم</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>الصورة</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>المادة</TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: 20 }} align="right" className={classes.tableCell}>السنة</TableCell>
                <TableCell className={classes.tableCell} style={{ fontWeight: "600", fontSize: 20 }} align="left">تعديل</TableCell>
                <TableCell className={classes.tableCell} style={{ fontWeight: "600", fontSize: 20 }} align="left"> ازالة</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lectures.map((lecture, index) => (
                <TableRow key={lecture.id}>
                  <TableCell align="right" className={classes.tableCell} >{+index + 1}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.title}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.price}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.description}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.semester}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >
                    <img width={"100px"} height={"100px"} src={lecture.imageUrl} alt={lecture.title} />
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.courseId.name}</TableCell>
                  <TableCell align="right" className={classes.tableCell} >{lecture.yearId.name}</TableCell>
                  <TableCell style={{ fontSize: 15 }} align="left">
                    <Button to={`/lectures/${lecture.id}?video=${lecture.isVideo}`} inverse style={{ fontWeight: "500", fontSize: 15 }} variant="contained">
                      تعديل المحاضرة
                    </Button>
                  </TableCell>
                  <TableCell style={{ fontSize: 15 }} align="left">
                    <Button danger onClick={() => openAndCloseShowConfirmModalHandler(lecture.id)} style={{ fontWeight: "500", fontSize: 15 }} variant="contained">
                      ازالة المحاضرة
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', direction: "ltr" }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1}>
              <FirstPage />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 1}>
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= totalPages}>
              <KeyboardArrowRight />
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= totalPages}>
              <LastPage />
            </IconButton>
          </div>
        </TableContainer> : <div style={{ display: "flex", justifyContent: "center" }} >
          انتظر قليلا او قم باضافة بعض البيانات
        </div>
      }
      <Modal
        show={showConfirmModal}
        onCancel={openAndCloseShowConfirmModalHandler}
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <Fragment>
            <Button inverse onClick={openAndCloseShowConfirmModalHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </Fragment>
        }
      >
        <p>Do you want to proceed and delete this place? </p>
      </Modal>
    </div>
  );
}

export default MyTable;
