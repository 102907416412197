import React, { Fragment, useEffect, useState, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import VideoUploader from "../../shared/components/FormElements/videoUploader";
import "./PlaceForm.css";
import { MenuItem, TextField } from "@material-ui/core";
import { useQuery } from '../../shared/hooks/query-hook'
import FileUploader from "../../shared/components/FormElements/FileUploader";

const UpdateLecture = () => {
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [loadedLectures, setLoadedLoadedLectures] = useState();
  const lectureId = useParams().lectureId;
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [courseName, setCourseName] = React.useState("");
  const [Courses, setCourses] = React.useState([]);
  const [yearName, setYearName] = React.useState("");
  const [years, setYears] = React.useState([]);
  const handleChangeYear = (event) => {
    setYearName(event.target.value);
    var elementPos = years.map(function (x) { return x.id; }).indexOf(event.target.value);
    var objectFound = years[elementPos];
    setCourses(objectFound.courses)
  };
  const handleChange = (event) => {
    setCourseName(event.target.value);
  };
  useEffect(() => {
    loadedLectures && setCourseName(loadedLectures.courseId);
    loadedLectures && setYearName(loadedLectures.yearId);
  }, [loadedLectures]);
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}course`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCourses(responseData.courses);
      } catch (error) { }
    };
    fetchCourses();
  }, [sendRequest, auth]);

  let query = useQuery();

  const [formState, inputHandler, setFormData] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      semester: {
        value: "",
        isValid: false,
      },
      price: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: true,
      },
      video: {
        value: null,
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    const fetchLectures = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}lecture/${lectureId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedLoadedLectures(responseData.lecture);
      } catch (error) { }
    };
    fetchLectures();
  }, [lectureId, sendRequest, setFormData, auth]);


  useEffect(() => {
    const fetchYears = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}user/get-years`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setYears(responseData.years);
      } catch (error) { }
    };
    fetchYears();
  }, [sendRequest, auth]);

  const lectureUpdateSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}lecture/${lectureId}`,
        "PATCH",
        JSON.stringify({
          title: formState.inputs.title.value,
          description: formState.inputs.description.value,
          semester: formState.inputs.semester.value,
          price: formState.inputs.price.value,
          year: yearName,
          CourseId: courseName,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json"

        }
      );
      history.push("/");
    } catch (error) { }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedLectures && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Couldn't find Lecture!</h2>
        </Card>
      </div>
    );
  }
  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedLectures && (
        <form className="place-form" onSubmit={lectureUpdateSubmitHandler}>
          <Input
            id="title"
            element="input"
            type="text"
            label="Title"
            validators={[VALIDATOR_REQUIRE()]}
            error="Please enter a valid title"
            onInput={inputHandler}
            initialValue={loadedLectures.title}
            initialValid={true}
          />
          <TextField
            id="year"
            select
            label="Year"
            value={yearName}
            onChange={handleChangeYear}
            helperText="Please select your course name"
            variant="outlined"
            fullWidth
          >
            {years &&
              years.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
          <Input
            id="semester"
            element="input"
            type="number"
            label="Semester"
            validators={[VALIDATOR_REQUIRE()]}
            error="Please enter a valid semester.(1 or 2) "
            onInput={inputHandler}
            initialValue={loadedLectures.semester}
            initialValid={true}
          />
          <Input
            id="price"
            element="input"
            type="number"
            label="Price"
            validators={[VALIDATOR_REQUIRE()]}
            error="Please enter a valid price"
            onInput={inputHandler}
            initialValue={loadedLectures.price}
            initialValid={true}
          />
          <Input
            id="description"
            element="textarea"
            type="text"
            label="Description"
            validators={[VALIDATOR_MINLENGTH(5)]}
            error="Please enter a valid Description (at least 5 characters)"
            onInput={inputHandler}
            initialValue={loadedLectures.description}
            initialValid={true}
          />

          <TextField
            id="CourseName"
            select
            label="Course Name"
            value={courseName}
            onChange={handleChange}
            helperText="Please select your course name"
            variant="outlined"
            fullWidth
          >
            {Courses &&
              Courses.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>

          <ImageUpload
            id="image"
            kind="image"
            onInput={inputHandler}
            initialValue={loadedLectures.imageUrl}
            initialValid={true}
            error={"Please Provide an Image For your Lecture."}
            HasNoBtn={true}
          />
          {query.get("video") &&
            <VideoUploader
              id="video"
              kind="video"
              onInput={inputHandler}
              initialValue={loadedLectures.videoUrl}
              initialValid={true}
              error={"Please Provide an Video For your Lecture."}
              HasNoBtn={true}
            />
          }

          <hr style={{ borderTop: "3px solid #bbb", margin: "30px" }} />
          <hr style={{ borderTop: "3px solid #bbb", margin: "30px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              inverse
              type="submit"
              style={{
                padding: "20px",
                borderRadius: "25px",
                border: "2px solid #1768AC",
              }}
            >
              UPDATE LECTURE
            </Button>
          </div>
        </form>
      )}
    </Fragment>
  );
};

export default UpdateLecture;
