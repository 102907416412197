import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));
function formatDate(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
}
function MyTable() {
  const auth = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const { sendRequest } = useHttpClient();

  const fetchRequests = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}order/getAllAcceptedOrders`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setOrders(responseData.orders);
      setTotal(responseData.totalPrice);
    } catch (error) { }
  };
  useEffect(() => {
    fetchRequests();
  }, [auth]);

  const classes = useStyles();

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  return (
    <div className={classes.root}>
      {orders.length > 0 ? (
        <TableContainer style={{ direction: "rtl" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الايميل
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الاسم بالكامل
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  {" "}
                  رقم الهاتف
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  الطلبات
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  تاريخ الطلب
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: 20 }}
                  align="right"
                  className={classes.tableCell}
                >
                  السعر الكامل للطلب
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.email}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.fullName}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.lectures.map((order) => order.title).join(", ")}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <p style={{ direction: "ltr" }}>
                      {formatDate(new Date(row.orderDate))}
                    </p>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.totalPrice}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow style={{ direction: "rtl" }}>
                <TableCell className={classes.tableCell} rowSpan={4} />
                <TableCell className={classes.tableCell} rowSpan={4} />
                <TableCell className={classes.tableCell} rowSpan={4} />
                <TableCell
                  style={{ fontWeight: "600", fontSize: "22" }}
                  colSpan={2}
                >
                  الاجمالي
                </TableCell>
                <TableCell
                  style={{ fontWeight: "600", fontSize: "22" }}
                  align="right"
                >
                  {ccyFormat(total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          انتظر قليلا او قم باضافة بعض البيانات
        </div>
      )}
    </div>
  );
}

export default MyTable;
